<template>
  <div class="text-center">
    <v-dialog
      v-model="dialog"
      persistent
      max-width="400"
      style="background: #1E1E1E!important;"
    >
      <v-card
        dark
        color="black"
        style="background: #1E1E1E!important;"
      >
        <v-card-title class="text-h5 account-title">
          Log in to <label class="target-platform">{{ platform }}</label> with these credentials
        </v-card-title>

        <v-card-text class="get-card-text">
          <v-text-field
            v-model="account"
            label="Account"
            type="text"
            outlined
            rounded
            readonly
            @click="selectText"
          >
            <template v-slot:append>
              <v-btn v-if="copied=='Account'" rounded color="info" class="copy-button text-capitalize" style="padding:0!important">
                Copied
              </v-btn>
              <v-btn v-else rounded color="primary" class="copy-button text-capitalize" style="padding:0!important" @click="copy('Account', account)">
                Copy
              </v-btn>
            </template>
          </v-text-field>
          <v-text-field
            v-model="password"
            label="Password"
            type="text"
            outlined
            rounded
            readonly
            @click="selectText"
          >
            <template v-slot:append>
              <v-btn v-if="copied=='Password'" rounded color="info" class="copy-button text-capitalize" style="padding:0!important">
                Copied
              </v-btn>
              <v-btn v-else rounded color="primary" class="copy-button text-capitalize" style="padding:0!important" @click="copy('Password', password)">
                Copy
              </v-btn>
            </template>
          </v-text-field>
        </v-card-text>

        <v-divider style="border-color: rgba(255, 255, 255, 0.12);" />

        <v-card-actions>
          <h4 v-show="downlaodApp"><a href="/auth/installapp">Install App to auto-fill password</a> </h4>
          <v-spacer />
          <v-btn
            class="text-capitalize"
            color="primary"
            rounded
            outlined
            @click="dialog = false"
          >
            Close
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <c-snack-bar ref="csnackbar" />
  </div>
</template>

<script>

import CSnackBar from './snackbar'
export default {
  name: 'GetAccount',
  components: {
    CSnackBar
  },
  props: {
    platform: {
      type: String,
      default: ''
    },
    account: {
      type: String,
      default: ''
    },
    password: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      dialog: false,
      copied: ''
    }
  },
  computed: {
    downlaodApp() {
      return !(window.isWebKit() || window.isAndroidWebView())
    }
  },
  methods: {
    selectText(e) {
      var el = e.target
      el.select()
    },
    copy(type, value) {
      if (window.isAndroidWebView() && window.AndroidCallback.copyToClipboard) {
        window.AndroidCallback.copyToClipboard(value)
        this.showCopied(type)
        this.showNackBar('info', `${type} Copied!`, 2000)
        return
      }

      navigator.clipboard.writeText(value).then(() => {
        this.showNackBar('info', `${type} Copied!`, 2000)
        this.showCopied(type)
        console.log('Async: Copying to clipboard was successful!')
      }, function(err) {
        console.error('Async: Could not copy text: ', err)
      })
    },
    showNackBar(color, message, timeout = 5000) {
      this.$refs.csnackbar.show(message, color, timeout)
    },
    showCopied(type) {
      this.copied = type
      setTimeout(() => {
        this.copied = ''
      }, 1500)
    }
  }
}
</script>

<style lang="scss" scoped>

.account-title{
  word-break: break-word;
  font-weight: bold !important;
  font-size: 15px !important;
}

.get-card-text{
  padding-bottom: 0px!important;
}

.copy-button{
  margin-right: -20px;
  margin-top: -10px;
  height: 40px;
  padding: 0px!important;
}

.target-platform{
  color: #E040FB;
}
</style>
